@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?18060330');
  src: url('../font/fontello.eot?18060330#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?18060330') format('woff2'),
       url('../font/fontello.woff?18060330') format('woff'),
       url('../font/fontello.ttf?18060330') format('truetype'),
       url('../font/fontello.svg?18060330#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: .2em;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

.icon-cancel:before { content: '\e800'; } /* '' */
.icon-briefcase:before { content: '\e801'; } /* '' */
.icon-book:before { content: '\e802'; } /* '' */
.icon-home:before { content: '\e803'; } /* '' */
.icon-info-circled:before { content: '\e804'; } /* '' */
.icon-callpopup:before { content: '\e805'; } /* '' */
.icon-crossorange:before { content: '\e806'; } /* '' */
.icon-directions:before { content: '\e807'; } /* '' */
.icon-left:before { content: '\e808'; } /* '' */
.icon-exchange_old:before { content: '\e809'; } /* '' */
.icon-phone:before { content: '\e80a'; } /* '' */
.icon-refund_banktransfer:before { content: '\e80b'; } /* '' */
.icon-refund_source:before { content: '\e80c'; } /* '' */
.icon-refund_storecredit:before { content: '\e80d'; } /* '' */
.icon-refund:before { content: '\e80e'; } /* '' */
.icon-schedulepickup:before { content: '\e80f'; } /* '' */
.icon-steps_cart:before { content: '\e810'; } /* '' */
.icon-steps_exchange:before { content: '\e811'; } /* '' */
.icon-steps_form:before { content: '\e812'; } /* '' */
.icon-steps_home:before { content: '\e813'; } /* '' */
.icon-steps_money:before { content: '\e814'; } /* '' */
.icon-steps_pack:before { content: '\e815'; } /* '' */
.icon-steps_print:before { content: '\e816'; } /* '' */
.icon-steps_redirect:before { content: '\e817'; } /* '' */
.icon-steps_ship:before { content: '\e818'; } /* '' */
.icon-steps_store:before { content: '\e819'; } /* '' */
.icon-arrows-ccw:before { content: '\e81a'; } /* '' */
.icon-picture:before { content: '\e81b'; } /* '' */
.icon-ok-circled:before { content: '\e81c'; } /* '' */
.icon-shortlist:before { content: '\e81d'; } /* '' */
.icon-down-open:before { content: '\e81e'; } /* '' */
.icon-up-open:before { content: '\e81f'; } /* '' */
.icon-steps_validity:before { content: '\e820'; } /* '' */
.icon-tickgreen:before { content: '\e821'; } /* '' */
.icon-warning:before { content: '\e822'; } /* '' */
.icon-steps_store-1:before { content: '\e823'; } /* '' */
.icon-star:before { content: '\e824'; } /* '' */
.icon-exchange_new-1:before { content: '\e825'; } /* '' */
.icon-radio_button_off:before { content: '\e826'; } /* '' */
.icon-radio_button_on:before { content: '\e827'; } /* '' */
.icon-filter:before { content: '\e828'; } /* '' */
.icon-popularity:before { content: '\e829'; } /* '' */
.icon-cancel-circled:before { content: '\e82a'; } /* '' */
.icon-search:before { content: '\e82b'; } /* '' */
.icon-shortlist-filled:before { content: '\e82c'; } /* '' */
.icon-phone-1:before { content: '\e82d'; } /* '' */
.icon-ic_gps_fixed:before { content: '\e82e'; } /* '' */
.icon-checked:before { content: '\e82f'; } /* '' */
.icon-star-filled:before { content: '\e830'; } /* '' */
.icon-ok-squared-1:before { content: '\e831'; } /* '' */
.icon-ic_share:before { content: '\e832'; } /* '' */
.icon-ic_info:before { content: '\e833'; } /* '' */
.icon-trash-empty:before { content: '\e834'; } /* '' */
.icon-check:before { content: '\e835'; } /* '' */
.icon-edit:before { content: '\e836'; } /* '' */
.icon-location:before { content: '\e837'; } /* '' */
.icon-greyone:before { content: '\e838'; } /* '' */
.icon-discount:before { content: '\e839'; } /* '' */
.icon-gift:before { content: '\e83a'; } /* '' */
.icon-power_sunglasses:before { content: '\e83b'; } /* '' */
.icon-without_power:before { content: '\e83c'; } /* '' */
.icon-price_hightolow:before { content: '\e83d'; } /* '' */
.icon-price_lowtohigh:before { content: '\e83e'; } /* '' */
.icon-sort:before { content: '\e83f'; } /* '' */
.icon-gps-fixed:before { content: '\e840'; } /* '' */
.icon-tag:before { content: '\e841'; } /* '' */
.icon-spinner:before { content: '\e842'; } /* '' */
.icon-attention:before { content: '\e84b'; } /* '' */
.icon-perticular:before { content: '\e850'; } /* '' */
.icon-party:before { content: '\e851'; } /* '' */
.icon-busisness:before { content: '\e852'; } /* '' */
.icon-casual:before { content: '\e853'; } /* '' */
.icon-cancel-1:before { content: '\e854'; } /* '' */
.icon-filter-icon:before { content: '\e855'; } /* '' */
.icon-menu:before { content: '\f008'; } /* '' */
.icon-check-empty:before { content: '\f096'; } /* '' */
.icon-pinterest-circled:before { content: '\f0d2'; } /* '' */
.icon-gplus:before { content: '\f0d5'; } /* '' */
.icon-mail-alt:before { content: '\f0e0'; } /* '' */
.icon-folder-empty:before { content: '\f114'; } /* '' */
.icon-keyboard:before { content: '\f11c'; } /* '' */
.icon-instagram:before { content: '\f16d'; } /* '' */
.icon-right:before { content: '\f178'; } /* '' */
.icon-whatsapp:before { content: '\f232'; } /* '' */
.icon-shopping-bag:before { content: '\f290'; } /* '' */
.icon-facebook-squared:before { content: '\f308'; } /* '' */
.icon-twitter:before { content: '\f309'; } /* '' */
